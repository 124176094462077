import React, { PureComponent } from "react"
import { Navbar, NavbarGroup, NavbarHeading, Button, Icon, Popover, Menu, MenuItem, MenuDivider, NavbarDivider, Tooltip } from "@blueprintjs/core"
import { Observer } from "mobx-react"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import global from "../stores/globalstore"
import login from "../stores/loginstore"
import history from "./history"


const Header = observer(() => (
    <Tooltip content="Kembali ke dashboard" position="bottom-right" usePortal={false}>
        <Link to="/">
            <NavbarHeading className="bold heading">
                <Observer>{() => global.session.clientname}</Observer>
            </NavbarHeading>
        </Link>
    </Tooltip>
))

const UserContext = () => {
    const logout = () => {
        login._logout()
    }

    const preference = () => {
        history.replace('/preference')
    }
    return (
        <Menu>
            <MenuItem onClick={() => preference()} icon="person" text="Preferensi" />
            <MenuDivider />
            <MenuItem onClick={logout} icon="log-out" text="Keluar" />
        </Menu>
    )
}

const UserButton = () => (
    <Popover content={<UserContext />} position="bottom-right" usePortal={false}>
        <Observer>{() =>
            <Button minimal icon="user" text={global.session.userfullname} className="btnUserTopBar" />
        }</Observer>
    </Popover>
)

export default class Topnav extends PureComponent {
    toggleShowMenu() {
        const btn = document.getElementById('wrapper')
        btn.classList.toggle('wrapper-active')
    }

    render() {
        return (
            <Navbar className="bprimary3 print-off">
                <NavbarGroup align="left">                    
                    <Header />
                </NavbarGroup>
                <NavbarGroup align="right">
                    <UserButton />
                    <NavbarDivider />
                    <Button minimal onClick={this.toggleShowMenu}>
                        <Icon icon="menu" />
                    </Button>                                 
                </NavbarGroup>
            </Navbar>
        )
    }
}