import React from "react"
import { Card, Menu, MenuItem, Icon } from "@blueprintjs/core"
import { observer } from "mobx-react"
import { Observer } from "mobx-react-lite"

import global from "../stores/globalstore"
import history from "./history"
import { apiUrl } from "./request"

const Sidenav = observer(() => {
    const parent = global.menu.filter(o => o.menu_induk === 0)
    const children = global.menu.filter(o => o.menu_induk > 0 && o.menu_aksi === 'view')
    return (
        <Card className="sidebar">
            <Menu>
                <div className="logo">
                    <Observer>{() =>
                        global.default.ismitra === 1 ?
                        (global.session.clientlogo &&
                        <a href={global.session.clienturl} target="_blank" rel="noopener noreferrer">
                            <img src={`${apiUrl}/${global.session.clientlogo}`} className="pointer" alt={global.session.clienturl} />
                        </a>)
                        :
                        (global.session.clientlogo && 
                        <img src={`${apiUrl}/${global.session.clientlogo}`} alt={global.session.clientname} />)
                    }</Observer>                    
                </div>
                {parent.map(p => (
                    children.filter(o => o.menu_induk === p.menu_id).length > 0 
                    && 
                    <MenuItem key={p.menu_id} text={p.menu_caption} icon={<Icon icon={p.menu_icon} intent="primary" />} popoverProps={{
                        hoverCloseDelay: 300,
                        captureDismiss: true,
                    }}>
                        {children.filter(o => o.menu_induk === p.menu_id).map(c => (
                            <MenuItem 
                                key={c.menu_id} 
                                onClick={() => {
                                    if (window.innerWidth < 769) {
                                        const btn = document.getElementById('wrapper')
                                        btn.classList.toggle('wrapper-active')
                                    }
                                    history.replace(`${c.menu_url}`)
                                }} 
                                text={c.menu_caption}
                                icon={<Icon icon={c.menu_icon} intent="primary" />}
                            />
                        ))}
                    </MenuItem>                
                ))}
            </Menu>
        </Card>
    )
})

export default Sidenav