import React from "react"
import { Route, Switch, withRouter, Link } from "react-router-dom"

import { Lazyload } from "./lazyload"
import Login from "../pages/login"
import Message from "../pages/message"
import global from "../stores/globalstore"
import { observer } from "mobx-react-lite"

// export const getSession = () => {
//     const jwt = Cookies.get('tip_cookie')
//     let session
//     try {
//         if (jwt) {
//             const base64Url = jwt.split('.')[1]
//             const base64 = base64Url.replace('-', '+').replace('_', '/')
//             console.log('session', session, jwt);
//             session = JSON.parse(window.atob(base64))
//         }
//     } catch (error) {
//         console.log('[getSession]', error)
//         return session
//     }
//     return session
// }

export const publicPath = [
    '/login',
    '/signup',
]

const ManagerRoute = observer(props => {
    const { location } = props
    return (
        global.default.client && publicPath.indexOf(location.pathname) === -1 ?
            <Route {...props} /> :            
            <div className="clover-container" style={{ textAlign: 'center', paddingTop: 'calc(50vh - 70px)' }}>
                <div style={{ fontSize: 50, fontWeight: 'bold' }}>401</div>
                <div>
                    Sesi login anda berakhir, 
                    Silakan <Link to="/login" className="primary3" style={{ fontWeight: 'bold' }}>klik disini</Link> untuk login
                </div>
            </div>
    )
})

class Router extends React.PureComponent {
    render() {
        const { location } = this.props
        return (
            <Switch location={location}>
                <Route path="/login" component={Login} />
                <Route path="/signup" component={Message} />
                <ManagerRoute exact path={`/:controller?/:action?/:id?`} render={props => <Lazyload key={location.pathname} param={props} />} />
            </Switch>
        )
    }
}

export default withRouter(Router)