import { observable, action } from "mobx"
import { get } from "../parts/request"

class store {
    @observable isDone = false
    @observable isError = false
    @observable error = ""

    @action _letVerification = async () => {
        const key = window.location.pathname.split('/').reverse()[0]
        return get(`signup/verifikasi/${key}`, {}).then(res => {
            if (res.error === null) {
                this.isDone = true
            } else {
                console.log('error masuk')
                this.isError = true
                this.error = res.error
            }
        })
    }
}

const message = new store()

export default message