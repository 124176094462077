import { observable, action } from "mobx";
import { get, post } from "../parts/request";
import Cookies from "js-cookie"
import { publicPath } from "../parts/router"
import { toast } from "../parts/label";
import history from "../parts/history";
import global from "./globalstore";
import { toastSuccess, toastWarning, toastCatch } from "../parts/label";
import {  } from "../parts/label";
import { socket } from "../parts/request";


class store {
    @observable isLoading = false
    @observable isShowPassword = false
    @observable input = {
        isMitra: 1,
        saveEmailAndUsername: false,
        // email: '13',
        // username: 'it',
        // password: '123',
    }
    @observable register = {
        isMitra: false,
    }
    @observable option = [
        { id: 'klien', label: 'Klien' },
        { id: 'mitra', label: 'Konsultan' },
    ]

    @action async _login() {
        this.isLoading = true
        post('auth/login', this.input).then(res => {
            this.isLoading = false
            if (res.error === null) {
                Cookies.set('tip_cookie', res.response.token)
                global.default = {
                    ismitra: res.response.ismitra,
                    client: res.response.client,
                    user: res.response.user,
                    fullname: res.response.userfullname,
                    super: res.response.super,
                }
                global.dashpriv = res.dashpriv
                global.session = res.response
                login._getMenuUser()
                get('user', {}).then(users => {
                    global.users = users.response
                    const selfIndex = global.users.findIndex(x => x.user_id === global.default.user)
                    if (selfIndex !== -1) {
                        global.users[selfIndex].connected = true
                    }
                    socket.on('user connected', o => {
                        console.log('connected', o)
                        const i = global.users.findIndex(x => x.user_id === o.user)
                        if (i !== -1) {
                            global.users[i].connected = true
                        }
                    })
                })
                if (this.input.saveEmailAndUsername) {
                    localStorage.email = this.input.email
                    localStorage.username = this.input.username
                    localStorage.saveEmailAndUsername = this.input.saveEmailAndUsername
                }
                this.input = { isMitra: 1, saveEmailAndUsername: false }
                history.replace('/')
            } else {
                toast.show({ message: res.error, intent: 'warning', icon: 'warning-sign' })
            }
        }).catch((err) => {
            this.isLoading = false
            toast.show({ message: 'tidak dapat terhubung ke server', intent: 'danger', icon: 'ban-circle' })
        })
    }

    @action async _logout() {
        return post('auth/logout', { token: Cookies.get('tip_cookie') }).then(res => {
            if (res.status === 200) {
                Cookies.remove('tip_cookie')
                Cookies.set('tip_cookie', res.token)
                global.default = {
                    ismitra: null,
                    client: null,
                    user: null,
                    fullname: null,
                    super: null,
                }
                global.session = {
                    token: null,
                    client: null,
                    clientname: null,
                    clientlogo: null,
                    clienturl: null,
                    user: null,
                    username: null,
                    userfullname: null,
                    ismitra: null,
                    theme: null,
                    super: null,
                }
                socket.removeAllListeners()
                history.replace('/login')
            }
        })
    }

    @action async _checkSession() {
        return get('auth/checkSession', { token: Cookies.get('tip_cookie') }).then(res => {
            if (res.error === null) {                
                global.done = true
                global.default = {
                    ismitra: res.response.ismitra,
                    client: res.response.client,
                    user: res.response.user,
                    fullname: res.response.userfullname,
                    super: res.response.super,
                }
                global.dashpriv = res.dashpriv
                global.session = res.response                
            } else {
                Cookies.remove('tip_cookie')
                setTimeout(() => history.replace('/login'), 100)
            }
            if (publicPath.indexOf(history.location.pathname) !== -1) {
                history.replace('/')
            }            
        })
    }

    @action async _getMenuUser() {
        return get(`auth/getMenuUser`, {}).then(res => {
            if (res.error === null) {                
                socket.emit('user connected', global.default)
                global.menu = res.response
            }
        })
    }

    @action async _getDashprivUser() {
        return get(`auth/getDashprivUser`, {}).then(res => {
            if (res.error === null) {
                socket.emit('dashpriv updated', global.default)
                global.dashpriv = res.response
            }
        })
    }

    @action _register = async () => {
        login.isLoading = true
        return post(`signup`, this.register).then(res => {
            login.isLoading = false
            if (res.error === null) {
                this.register = {}
                toastSuccess(res.response)
            } else {
                toastWarning(res.error)
                this.register.email = ''
            }
        })
        .catch(() => {
            login.isLoading = false
            toastCatch()
        })
    }
}

const login = new store()

export default login