import React, { PureComponent } from "react"
import { Link } from "react-router-dom"
import { Card, Spinner, Icon, Colors } from "@blueprintjs/core"
import { Observer } from "mobx-react-lite"
import message from "../stores/messagestore"

const containerStyle = {
    height: '90%',
    fontSize: 16,
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center',
}

export default class Message extends PureComponent {

    render () {
        message._letVerification()

        return <div style={containerStyle}>
            <Card>                
                <Observer>{() =>
                    message.isDone ? 
                    <>           
                        {
                            message.isError ?
                            <>{message.error}</>
                            :
                            <>
                            <Icon icon="tick" size={70} color={Colors.GREEN3} /> 
                            &nbsp; Terimakasih telah menyelesaikan pendaftaran anda. <br />
                                silakan klik <b style={{ color: Colors.BLUE3 }}><Link to="/login">disini</Link></b> untuk login.
                            </>
                        }         
                    </>
                    :
                    <>
                        {
                            message.isError ?
                            <>{message.error}</>
                            :
                            <>
                            <Spinner intent="primary" size={40} />
                            <br /> Mohon tunggu, sedang memproses pendaftaran anda ...  
                            </>
                        }                                 
                    </>
                }</Observer>
            </Card>
        </div>
    }
}
