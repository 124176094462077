import React, { PureComponent } from "react"
import { withRouter } from "react-router-dom"
import Cookies from "js-cookie"
import Router from "./parts/router"
import Topnav from "./parts/topnav"
import Sidenav from "./parts/sidenav"
import login from "./stores/loginstore"
import { Observer } from "mobx-react-lite"
import global from "./stores/globalstore"
import { publicPath } from "./parts/router"
import { socket } from "./parts/request"
import { toastWarning } from "./parts/label"
import "moment/locale/id"
import { get } from "./parts/request"

class Body extends PureComponent {

    componentDidMount() {
        if (Cookies.get('tip_cookie')) {                                    
            socket.on('user updated', o => {
                console.log('user updated', o)
                toastWarning(`Data user anda telah diperbarui oleh "${o.fullname}"`)
                login._checkSession().then(() => login._getMenuUser())            
            })   
            socket.on('dashpriv updated', o => {
                console.log('dashpriv updated', o)
                toastWarning(`Hak akses dashboard anda telah diperbarui oleh "${o.fullname}"`)
                login._getDashprivUser()
            })          
            socket.on('user disconnected', o => {
                console.log('disconnected', o)
                const selfIndex = global.users.findIndex(x => x.user_id === global.default.user)
                const index = global.users.findIndex(o => o.user_id === o.user)
                global.users[selfIndex].connected = false
                if (index !== -1) {
                    global.users[index].connected = false
                }
            })
            login._checkSession().then(() => {
                get('user', {}).then(users => {
                    global.users = users.response
                    const selfIndex = global.users.findIndex(x => x.user_id === global.default.user)
                    if (selfIndex !== -1) {
                        global.users[selfIndex].connected = true
                    }
                    socket.on('user connected', o => {
                        console.log('connected', o)
                        const i = global.users.findIndex(x => x.user_id === o.user)
                        if (i !== -1) {
                            global.users[i].connected = true
                        }
                    })
                })
                login._getMenuUser()
            })
        } else {
            global.done = true
            const a = '/'+window.location.href.replace(window.location.origin + '/', '').split('/')[0]
            if (publicPath.indexOf(a) === -1) {
                this.props.history.replace("/login")
            }
        }    
    }

    componentWillUnmount() {
        socket.removeAllListeners()
    }

    render() {
        return (
            <>
                <Observer>{() => global.default.client && <Topnav />}</Observer>
                <Observer>{() =>
                    <div className="wrapInline" style={{ height: !global.default.client ? '100vh' : '' }}>                    
                        <div className="wrapRouter">
                            <Observer>{() =>
                                global.done && <Router />
                            }</Observer>
                        </div>
                        <Observer>{() => global.default.client &&
                            <div id="wrapper" className={`wrapSidebar print-off ${window.innerWidth > 768 && "wrapper-active"}`}>
                                <Sidenav />
                            </div>
                        }</Observer>
                    </div>
                }</Observer>
            </>
        )
    }
}

export default withRouter(Body)