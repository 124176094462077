import { observable } from "mobx"

class store {
    @observable appname = 'Konsultan'   
    @observable done = false
    @observable params = {}
    @observable menu = []
    @observable users = []
    @observable dashpriv = []
    @observable session = {
        token: null,
        client: null,
        clientname: null,
        clientlogo: null,
        clienturl: null,
        user: null,
        username: null,
        userfullname: null,
        ismitra: null,
        theme: null,
        super: null,
    }
    @observable default = {
        ismitra: null,
        client: null,
        user: null,
        fullname: null,
        super: null,
    }
}

const global = new store()

export default global