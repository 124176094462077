import React from "react"
import { Router } from "react-router-dom"
import Body from "./body"
import history from "./parts/history"


function App() {
  console.log("%c Dibuat dengan ⚛️ dan ❤️. by: m.dary.nur@gmail.com 🤣", "font-size: 30px; font-weight: bold");
  return (
    <Router history={history}>
      <Body />
    </Router>
  )
}

export default App;
