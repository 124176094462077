import React, { PureComponent } from "react"
import { Spinner } from "@blueprintjs/core"
import global from "../stores/globalstore"


export const LoadingIndicator = () => {
    document.title = 'Memuat halaman ...'
    return (
        <div className="clover-container" style={{ textAlign: 'center', paddingTop: 'calc(50vh - 80px)' }}>
            <Spinner intent="primary" size={70} />    
        </div>
    )
}

const PageNotFound = () => {
    document.title = '404 - Halaman tidak ditemukan'
    return (
        <div className="clover-container" style={{ textAlign: 'center', paddingTop: 'calc(50vh - 80px)' }}>
            <div style={{ fontSize: 50, fontWeight: 'bold' }}>404</div>
            Halaman tidak ditemukan
        </div>
    )
}

export const PageUnauthorized = () => {
    document.title = '401 - Halaman diblokir'
    return (
        <div className="clover-container" style={{ textAlign: 'center', paddingTop: 'calc(50vh - 70px)' }}>
            <div style={{ fontSize: 50, fontWeight: 'bold' }}>401</div>
            Halaman diblokir karena anda tidak mempunyai akses ke halaman ini
        </div>
    )
}

export class Lazyload extends PureComponent {

    constructor (props) {
        super(props)
        this.state = {
            Module: null
        }
    }

    async componentDidMount() {
        const { param } = this.props
        const { params } = param.match
        global.params = {...params, ...{
            action: params.action,
            actionLabel: params.action === 'add' ? 'Tambah' : 'Ubah',
            actionButton: params.action === 'add' ? 'Simpan' : 'Update' ,
            isAdd: params.action === 'add',
            isEdit: params.action === 'edit',
            isDetail: params.action === 'detail',
        }}
        switch (true) {
            case (typeof params.controller === 'undefined'):
                if (global.default.ismitra === 0) {
                    await import(`../pages/basic/basic`)
                        .then(Module => this.setState({ Module: Module.default }))
                        .catch((e) => { console.log(e); this.setState({ Module: PageNotFound }) })
                }
                if (global.default.ismitra === 1) {
                    await import(`../pages/main/main`)
                        .then(Module => this.setState({ Module: Module.default }))
                        .catch((e) => { console.log(e); this.setState({ Module: PageNotFound }) })
                }
            break;      
            case (typeof params.action === 'undefined'):
                await import(`../pages/${params.controller}/${params.controller}`)
                    .then(Module => this.setState({ Module: Module.default }))
                    .catch((e) => { console.log(e); this.setState({ Module: PageNotFound }) })
            break;
            case (params.action === 'view'):
                await import(`../pages/${params.controller}/${params.controller}view`)
                    .then(Module => this.setState({ Module: Module.default }))
                    .catch((e) => { console.log(e); this.setState({ Module: PageNotFound }) })
            break;
            case (params.action === 'detail'):
                await import(`../pages/${params.controller}/${params.controller}detail`)
                    .then(Module => this.setState({ Module: Module.default }))
                    .catch((e) => { console.log(e); this.setState({ Module: PageNotFound }) })
            break;   
            case (params.action === 'add' || params.action === 'edit'):
                await import(`../pages/${params.controller}/${params.controller}input`)
                    .then(Module => this.setState({ Module: Module.default }))
                    .catch((e) => { console.log(e); this.setState({ Module: PageNotFound }) })
            break;
            default:
                await import(`../pages/${params.controller}/${params.action}${params.controller}`)
                    .then(Module => this.setState({ Module: Module.default }))
                    .catch((e) => { console.log(e); this.setState({ Module: PageNotFound }) })
        }
    }

    render () {
        const { Module } = this.state;
        return (
            <>
                {Module ? <Module /> : <LoadingIndicator />}
            </>
        )
    }
}