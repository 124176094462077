import global from "../stores/globalstore"
import io from "socket.io-client"

export const appUrl = window.location.origin

export const apiUrl = 'https://api.konsultan.focusplus.id/api'
export const socket = io('https://api.konsultan.focusplus.id:13131')

// export const apiUrl = 'http://localhost/konsultan_api/api'
// export const socket = io('http://localhost:13131')

// export const apiUrl = 'http://192.168.0.154/konsultan_api/api'
// export const socket = io('http://192.168.0.154:13131')

// export const apiUrl = 'http://192.168.43.58/konsultan_api/api'
// export const socket = io('http://192.168.43.58:13131')

export const emit = (event, obj) => socket.emit(event, { ...global.default, ...obj })

export const get = async (requestUrl, params) => {
    const parameter = { ...params, ...global.default }
    const base = requestUrl.indexOf('http') !== -1 ? "" : apiUrl + "/"
    const esc = encodeURIComponent
    const queryParams = "?" + Object.keys(parameter).map(k => esc(k) + '=' + esc(parameter[k])).join('&')
    return await fetch(base + requestUrl + queryParams, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain',
        },

    })
    .then(raw => raw.json())
}

export const post = async (requestUrl, params) => {
    const parameter = { ...params, ...global.default }
    const base = requestUrl.indexOf('http') !== -1 ? "" : apiUrl + "/"
    return await fetch(base + requestUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(parameter)
    })
    .then(raw => raw.json())
}

export const postFile = async (requestUrl, params) => {
    const parameter = { ...params, ...global.default }
    let data = new FormData()
    Object.entries(parameter).map(([key, val]) => data.append(key, val))
    const base = requestUrl.indexOf('http') !== -1 ? "" : apiUrl + "/"
    return await fetch(base + requestUrl, {
        method: 'POST',
        body: data
    })
        .then(raw => raw.json())
}

export const put = async (requestUrl, params) => {
    const parameter = { ...params, ...global.default }
    const base = requestUrl.indexOf('http') !== -1 ? "" : apiUrl + "/"
    return await fetch(base + requestUrl, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(parameter)
    })
    .then(raw => raw.json())
}

export const del = async (requestUrl, params) => {
    const parameter = { ...params, ...global.default }
    const base = requestUrl.indexOf('http') !== -1 ? "" : apiUrl + "/"
    const esc = encodeURIComponent
    const queryParams = "?" + Object.keys(parameter).map(k => esc(k) + '=' + esc(parameter[k])).join('&')
    return await fetch(base + requestUrl + queryParams, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain',
        },

    })
    .then(raw => raw.json())
}