import React from "react"
import { Toaster } from "@blueprintjs/core"


export function thousand(x, precision = null) {
    if (precision) {
        x = parseFloat(x).toFixed(precision)
    }
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
}

export const toast = Toaster.create({
    position: 'top',
})

export const toastSuccess = (msg, icon = 'tick-circle') => toast.show({
    message: msg,
    intent: 'success',
    icon: icon,
})

export const toastWarning = (msg, icon = 'warning-sign') => toast.show({
    message: msg,
    intent: 'warning',
    icon: icon,
})

export const toastDanger = (msg, icon = 'warning-sign') => toast.show({
    message: msg,
    intent: 'danger',
    icon: icon,
})

export const toastCatch = () => toast.show({
    message: 'Kesalahan saat memproses data, silakan hubungi developer',
    intent: 'danger',
    icon: 'warning-sign',
})

export const Col = ({ children, xs, sm, md, lg, className }) => (
    <div className={`${(lg || md || sm || xs) ? 'col-lg-' + (lg || md || sm || xs) : ''} ${(md || sm || xs) ? 'col-md-' + (md || sm || xs) : ''} ${(sm || xs) ? 'col-sm-' + (sm || xs) : ''} ${xs ? 'col-xs-' + xs : ''} ${className || ''}`}>
        {children}
    </div>
)