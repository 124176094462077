import React, { PureComponent } from "react"
import { InputGroup, FormGroup, Tooltip, Button, Colors, H4, Tabs, Tab, Checkbox } from "@blueprintjs/core"
import { Observer } from "mobx-react"
import login from "../stores/loginstore"
import { SelectGroup } from "../parts/input"

const containerStyle = {
    height: '90%',
    fontSize: 16,
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center',
}

export default class Login extends PureComponent {

    componentDidMount () {
        document.title = "Konsultan App"
        if (localStorage.email) {
            login.input.email = localStorage.email
        }
        if (localStorage.username) {
            login.input.username = localStorage.username
        } 
        if (localStorage.saveEmailAndUsername) {
            login.input.saveEmailAndUsername = true
        }
    }

    handleTabChange = o => {
        login.input.isMitra = o
    }

    render () {
        return <div style={containerStyle}>
            <div className="loginbox">                
                <Observer>{() => 
                    <Tabs                          
                        id="login" 
                        onChange={this.handleTabChange} 
                        selectedTabId={login.input.isMitra}
                    >
                        <Tab id={0} title="Klien" panel={<PanelLogin />} />
                        <Tab id={1} title="Konsultan" panel={<PanelLogin />} />
                        <Tab id={2} title="Daftar baru" panel={<PanelRegister />} />
                    </Tabs>                
                }</Observer>                
            </div>
        </div>
    }
}

const PanelLogin = () => (
    <>
        <H4 className="primary3" style={{ textAlign: 'center' }}>
            Login <Observer>{() => login.input.isMitra === 1 ? `Konsultan` : `Klien`}</Observer>
        </H4>
        <Observer>{() =>
            <TextInput
                className={1}
                label="Email Perusahaan"
                value={login.input.email || ''}
                onChange={e => login.input.email = e.target.value}
            />
        }</Observer>
        <Observer>{() =>
            <TextInput
                className={2}
                label="Username"
                value={login.input.username || ''}
                onChange={e => login.input.username = e.target.value}
            />
        }</Observer>
        <PasswordInput />
        <FormGroup>
            <Observer>{() =>
                <Checkbox
                    checked={login.input.saveEmailAndUsername || false}
                    onChange={() => login.input.saveEmailAndUsername = !login.input.saveEmailAndUsername}
                    label="Simpan email & username di browser"
                />
            }</Observer>
        </FormGroup>
        <br />
        <Observer>{() =>
            <Button 
                onClick={() => login._login()} 
                loading={login.isLoading} 
                large 
                fill 
                icon="log-in" 
                text="Masuk" 
                intent="primary" 
            />
        }</Observer>
    </>
)

const PanelRegister = () => (
    <>
        <H4 className="primary3" style={{ textAlign: 'center' }}> Daftar </H4>
        <br /> 
        <FormGroup label={"Daftar sebagai"}>
            <Observer>{() =>
                <SelectGroup
                    large
                    value={login.register.registerAs}
                    onChange={val => login.register.registerAs = val}
                    items={login.option}
                    itemId="id"
                    itemLabel="label"
                    text={o => o.label}
                    className="ori"
                />
            }</Observer>       
        </FormGroup>
        <Observer>{() =>
            <TextInput
                label="Nama Perusahaan"                
                value={login.register.name || ''}
                onChange={e => login.register.name = e.target.value}
            />
        }</Observer>
        <Observer>{() =>
            <TextInput
                helperText="Email ini akan digunakan login setiap user"
                label="Email Perusahaan"
                value={login.register.email || ''}
                onChange={e => login.register.email = e.target.value}
                type="email"
            />
        }</Observer>
        <br />
        <Observer>{() =>
            <Button 
                onClick={() => login._register()} 
                loading={login.isLoading} 
                disabled={
                    !login.register.registerAs || login.register.registerAs === '' ||
                    !login.register.name || login.register.name === '' ||
                    !login.register.email || login.register.email === ''
                }
                large 
                fill 
                icon="office" 
                text="Daftar" 
                intent="primary" 
            />
        }</Observer>
    </>
)

const TextInput = ({ id, label, value, onChange, onKeyDown, helperText, type }) => {
    return <FormGroup label={label} helperText={helperText}>        
        <InputGroup                
            className={id}
            large
            value={value}
            onChange={e => onChange(e)}
            onKeyDown={onKeyDown}
            type={type}
        />        
    </FormGroup>
}

const PasswordInput = () => {
    return <FormGroup label="Password">
        <Observer>{() =>
            <InputGroup
                large
                rightElement={<TogglePassword />}
                type={login.isShowPassword ? "text" : "password"}
                value={login.input.password || ''}
                onChange={e => login.input.password = e.target.value}
            />
        }</Observer>
    </FormGroup>
}

const TogglePassword = () => (
    <Tooltip content={`${login.isShowPassword ? "Sembunyikan" : "Perlihatkan"} Password`} position="top-right">
        <Button
            minimal
            icon={login.isShowPassword ? "unlock" : "lock"}
            color={Colors.RED5}
            onClick={() => login.isShowPassword = !login.isShowPassword}
        />
    </Tooltip>
)